import { Container } from '@mui/material';
import React from 'react';
import { Page } from '../../components/GobalElement';
import styles from "./Articles.module.scss";
import pic20 from '../../assets/acticle/pic-20.png'
import pic21 from '../../assets/acticle/pic-21.png'
import pic23 from '../../assets/acticle/pic-23.png'
import pic24 from '../../assets/acticle/pic-24.png'

const EcoStickerSafety = () => {
    return (
        <Page title='มาตรฐานความปลอดภัยใน ECO Sticker มีอะไรบ้าง'>
            <div className={styles.pageContent}>
                <Container>
                    <div className={styles.pageContainer}>
                        <span className={styles.pageTopic}>มาตรฐานความปลอดภัยใน ECO Sticker มีอะไรบ้าง</span>
                        <span className={styles.pageTopicSecondary}>ABS+ESC</span>
                        <span className={styles.pageSpan}>มาตรฐานความปลอดภัย</span>
                        <div className={styles.sectionBreak} />
                        <div className={styles.paraghapBox}>
                            <span>
                                ระบบเบรค <span className={styles.textGreen}>ABS</span> หรือ <span className={styles.textGreen}>Anti-Lock Brake System</span> คือ ระบบเพื่อการเบรคที่ปลอดภัย สร้างขึ้นเพื่อป้องกันการเบรคจน “ล้อล็อก” จนทำให้รถเสียหลักหรือไถลจนคุมทิศทางไม่ได้ รถที่ใช้งานระบบเบรค <span className={styles.textGreen}>ABS</span> จึงสามารถคุมทิศทางของรถได้ขณะเบรคฉุกเฉิน เรียกได้ว่าเป็นตัวช่วยเพื่อความปลอดภัยของผู้ขับขี่ในเวลาเกิดอุบัติเหตุหรือเวลาที่ต้องเบรคกระทันหัน
                            </span>
                        </div>

                        {/* hide */}
                        {/* <div className={styles.imgSection}>
                            <img src={pic20} className={styles.imgTopSecondary} />
                        </div>
                        <span>ภาพจาก www.isuzu-tis.com/innovative-iesc</span> */}
                        
                        <div className={styles.sectionBreak} />
                        <div className={styles.paraghapBox}>
                            <span>
                                ระบบป้องกันการพลิกคว่ำและลื่นไถลขณะเข้าโค้ง <span className={styles.textGreen}>ESC</span> หรือ <span className={styles.textGreen}>Electronic Stability Control</span> คือ ระบบสั่งการควบคุมการเบรกของแต่ละล้อให้เหมาะสม
                                และควบคุมการทำงานของเครื่องยนต์โดยอัตโนมัติเมื่อรถสูญเสียการทรงตัว
                            </span>
                        </div>

                        {/* hide */}
                        {/* <div className={styles.imgSection}>
                            <img src={pic21} className={styles.imgTopSecondary} />
                        </div>
                        <span>ภาพจาก www.isuzu-tis.com/innovative-iesc</span> */}

                        <div className={styles.sectionBreak} />
                        <span className={styles.pageTopicSecondary}>UN R13 และ UN R13H</span>
                        <span className={styles.pageSpan}>มาตรฐานระบบเบรก</span>
                        <div className={styles.sectionBreak} />
                        <div className={styles.paraghapBox}>
                            <span>
                                UN R13 (สำหรับรถกระบะ / รถบรรทุกเพื่อการพาณิชย์)
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                UN R 13H (สำหรับรถยนต์นั่งส่วนบุคคล)
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>ข้อกำหนดทางเทคนิค ประกอบด้วยการทดสอบ 7 ลักษณะ ดังนี้</span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &ensp;&ensp;1) การทดสอบสมรรถนะห้ามล้อ ขณะระบบเบรกเย็น (Type O) เป็นการทดสอบสมรรถนะของระบบเบรกในขณะที่รถยนต์วิ่งด้วยความเร็ว 100 กิโลเมตรต่อชั่วโมง จนกระทั่งหยุดนิ่ง เพื่อวัดค่าความหน่วงและระยะทางในการหยุดรถ ในแต่ละสภาวะ ดังนี้
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &emsp;&emsp;1.1 สภาวะปกติที่ระบบเบรกทำงานอย่างสมบูรณ์ ประกอบด้วย การทดสอบรถยนต์ที่ 2 ระดับความเร็ว ได้แก่ ระดับความเร็วปกติ คือ 100 กิโลเมตรต่อชั่วโมง และระดับความเร็วสูง (ร้อยละ 80 ของความเร็วสูงสุด แต่ไม่เกิน 160 กิโลเมตรต่อชั่วโมง)
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &emsp;&emsp;1.2 สภาวะระบบเบรกวงจรซ้ายหรือขวา ไม่ทำงาน
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &emsp;&emsp;1.3 สภาวะหม้อลมช่วยเบรกไม่ทำงาน
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &emsp;&emsp;1.4 สภาวะระบบห้ามล้อแบบป้องกันการล็อก (ABS : Antilock Braking System) ไม่ทำงาน
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &emsp;&emsp;1.5 สภาวะระบบห้ามล้อแบบป้องกันการล็อก (ABS : Antilock Braking System)/ ระบบกระจายแรงเบรก (EBD : Electronic Brake Force Distribution) ไม่ทำงาน
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                ทั้งนี้ การทดสอบข้างต้น จะกระทำทั้งกรณีมวลบรรทุกสูงสุด (Laden) และมวลรถเปล่า (Unladen) รวมทั้ง การทดสอบในกรณีเครื่องยนต์ดับ
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &ensp;&ensp;2) การทดสอบสมรรถนะห้ามล้อ ขณะระบบเบรกร้อน (Type I) เป็นการทดสอบสมรรถนะของระบบเบรกในสภาวะที่มีความร้อนสะสม ทั้งในกรณีความร้อนสะสมสูงและความร้อนสะสมไม่สูง โดยการจำลองสถานการณ์การเบรก จำนวนหลายครั้งอย่างต่อเนื่อง เพื่อทาให้เบรกร้อนขึ้น หลังจากนั้น จึงทดสอบการทำงานของระบบเบรกในขณะที่รถยนต์วิ่งด้วยความเร็ว 100 กิโลเมตรต่อชั่วโมง จนกระทั่งหยุดนิ่ง เพื่อวัดค่าความหน่วงแล้วนำไปเปรียบเทียบกับเกณฑ์มาตรฐาน
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &ensp;&ensp;3) การทดสอบเบรกมือ เป็นการทดสอบความสามารถในการจอดรถบนพื้นเอียง ที่มีระดับความชันร้อยละ 20 (กรณีมวลบรรทุกสูงสุด) และระดับความชันร้อยละ 12 (กรณีมวลบรรทุกสูงสุด+Trailer) เพื่อวัดค่าแรงที่ใช้ในการเบรกจากมือและเท้า รวมทั้งความสามารถในการหยุดรถ ที่ระดับความเร็ว 30 กิโลเมตรต่อชั่วโมง โดยใช้เบรกมือ
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &ensp;&ensp;4) การทดสอบระบบช่วยเหลือการเบรก (BAS : Brake Assist System) เป็นการทดสอบสมรรถนะของระบบช่วยเหลือการเบรก (ระบบควบคุมแรงเบรก)
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &ensp;&ensp;5) การทดสอบระบบห้ามล้อแบบป้องกันการล็อก (ABS : Antilock Braking System-ABS) เป็นการทดสอบสมรรถนะของระบบ ABS ซึ่งโดยหลักการแล้ว ประสิทธิภาพในการเบรกเมื่อ ระบบ ABS ทำงาน (ด้วยการจับ-ปล่อยเบรกเป็นบางช่วง) จะต้องไม่ส่งผลให้ความสามารถในการเบรกลดต่ากว่าค่าที่กำหนด (ไม่ต่ากว่าร้อยละ 75 ของแรงเบรกสูงสุด) ทั้งนี้ จะมีการทดสอบสมรรถนะของ ABS ในการหยุดรถบนพื้นผิวที่แตกต่างกัน 5 ลักษณะ ดังนี้
                            </span>
                            <span className={styles.paraghapBreak} /><span>
                                &emsp;&emsp;5.1 การหยุดรถบนพื้นผิวที่มีแรงเสียดทานสูง (Hi-μ)
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &emsp;&emsp;5.2 การหยุดรถบนพื้นผิวที่มีแรงเสียดทานต่า (Low-μ)
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &emsp;&emsp;5.3 การหยุดรถจากพื้นผิวที่มีแรงเสียดทานสูงไปยังพื้นผิวที่มีแรงเสียดทานต่า
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &emsp;&emsp;5.4 การหยุดรถจากพื้นผิวที่มีแรงเสียดทานต่าไปยังพื้นผิวที่มีแรงเสียดทานสูง
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &emsp;&emsp;5.5 การหยุดรถในกรณี ล้อของรถด้านหนึ่งอยู่บนพื้นผิวที่มีแรงเสียดทานสูง และล้อของรถอีกด้านหนึ่งอยู่บนพื้นผิวที่มีแรงเสียดทานต่า
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                นอกจากนี้แล้ว ยังมีการทดสอบการทำงานของระบบไฟเตือน และสภาพการวิ่งของรถยนต์ในขณะเบรกในกรณีที่ระบบ ABS ไม่ทำงาน รวมทั้ง การทดสอบการทำงานของระบบ ABS ภายใต้การรบกวนของคลื่นแม่เหล็กไฟฟ้า (Electromagnetic Compatibility) อีกด้วย
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &ensp;&ensp;6) การทดสอบสัญญาณไฟฉุกเฉิน (Emergency Stop Signal)เป็นการทดสอบการให้สัญญาณไฟฉุกเฉินซึ่งจะต้องทำงาน หากมีการเบรกอย่างแรง (กรณีฉุกเฉิน)
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &ensp;&ensp;7) การทดสอบระบบควบคุมเสถียรภาพแบบอิเล็กทรอนิกส์ (Electronic Stability Control System-ESC System) เป็นการทดสอบสมรรถนะของระบบ ESC โดยการสร้างสถานการณ์การหลบหลีกกะทันหันในขณะที่รถยนต์วิ่งด้วยความเร็ว 80 กิโลเมตรต่อชั่วโมง ด้วยการใช้ระบบอัตโนมัติควบคุมการหักเลี้ยวรถยนต์ไปกลับอย่างกะทันหัน (องศาการเลี้ยวสูงสุดที่ 270 องศา) ทั้งนี้ รถยนต์ที่ผ่านมาตรฐานจะต้องไม่เสียหลักและยังคงรักษาทิศทางการเคลื่อนที่ได้อย่างสมบูรณ์ หรือ มีการเบี่ยงเบนได้ไม่เกินค่าที่กำหนด
                            </span>
                        </div>
                        <div className={styles.sectionBreak} />
                        <span className={styles.pageTopicSecondary}>UN R94 และ UN R95</span>
                        <span className={styles.pageSpan}>มาตรฐานการปกป้องผู้โดยสาร</span>
                        <div className={styles.sectionBreak} />
                        <div className={styles.paraghapBox}>
                            <span>
                                UN R94 การชนด้านหน้า
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                การทดสอบเพื่อพิสูจน์ความปลอดภัยของยานยนต์ในการปกป้องผู้ขับขี่ และผู้โดยสารจากการชนด้านหน้า
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                ข้อกำหนดทางเทคนิค
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                UN R94 เป็นการทดสอบโดยรถยนต์ทดลอง ซึ่งจะมีหุ่นจำลองของผู้ขับขี่และผู้โดยสารเคลื่อนที่โดยใช้เครื่องมือฉุดลาก ด้วยความเร็วระหว่าง 56-57 กิโลเมตรต่อชั่วโมง พุ่งเข้าชนแบบจำลองหัวรถยนต์ที่สามารถยุบตัวได้ แบบเยื้องศูนย์ด้านหน้า 40% ของรถยนต์ภายหลังการชนจะมีการ
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>-  สภาพของหุ่นจำลอง (ผู้ขับขี่และผู้โดยสาร) ได้แก่ การบาดเจ็บที่หัว , การบาดเจ็บที่คอ , การรับแรงที่หน้าอก , แรงกดที่หน้าแข้ง , การเคลื่อนตัวของข้อต่อหัวเข่า และการรับแรงที่ขา</span>
                            <span className={styles.paraghapBreak} />
                            <span>-  การรั่วของน้ำมันเชื้อเพลิง และระบบป้อนน้ำมันเชื้อเพลิงสามารถเกิดขึ้นได้ แต่ต้องไม่เกินกว่า 30 กรัมต่อนาที</span>
                            <span className={styles.paraghapBreak} />
                            <span>-  การเคลื่อนที่ของพวงมาลัย ต้องไม่สูงเกินกว่า 80 มิลลิเมตรในแนวดิ่ง และต้องไม่เคลื่อนตัวไปในข้างหลังในแนวระดับ 100 มิลลิเมตร</span>
                            <span className={styles.paraghapBreak} />
                            <span>-  ต้องเปิดประตูได้อย่างน้อยหนึ่งประตูและสามารถนำหุ่นจำลองออกจากรถทดสอบได้</span>
                            <span className={styles.paraghapBreak} />
                            <span>-  ต้องปลดล็อคระบบ Restraint โดยใช้แรงไม่เกิน 60 นิวตัน</span>
                        </div>
                        <div className={styles.sectionBreak} />
                        <div className={styles.imgSection}>
                            <img src={pic23} className={styles.imgTopSecondary} />
                        </div>

                        <div className={styles.sectionBreak} />
                        <div className={styles.paraghapBox}>
                            <span>
                                UN R95 การชนด้านข้าง
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                ข้อกำหนดทางเทคนิค UN R95 จะเป็นการทดสอบโดยนำแบบจำลองหัวรถยนต์ที่สามารถยุบตัวได้ เคลื่อนที่พุ่งเข้าชนรถยนต์ทดสอบซึ่งจอดอยู่นิ่งในแนวตั้งฉากด้านข้าง (ด้านผู้ขับขี่) ด้วยความเร็ว 50 กิโลเมตรต่อชั่วโมงภายหลังการชน จะมีการทดสอบดังนี้
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span className={styles.paraghapBreak} />
                            <span>-  สภาพของหุ่นจำลอง (ผู้ขับขี่) ได้แก่ การบาดเจ็บที่หัว , การบาดเจ็บที่หน้าอก , แรงกดที่หน้าท้องและแรงกดที่หัวหน่าว</span>
                            <span className={styles.paraghapBreak} />
                            <span>-  การรั่วของน้ำมันเชื้อเพลิง และระบบป้อนน้ำมันเชื้อเพลิง สามารถเกิดขึ้นได้แต่ต้องไม่เกินกว่า 30 กรัมต่อนาที</span>
                            <span className={styles.paraghapBreak} />
                            <span>-  ต้องสามารถนำหุ่นจำลองออกจากรถทดสอบได้</span>
                            <span className={styles.paraghapBreak} />
                            <span>-  ต้องสามารถปลดล็อคระบบป้องกันได้</span>
                            <span className={styles.paraghapBreak} />
                            <span>-  ไม่มีการเสียรูปของอุปกรณ์ภายในที่ทำให้แหลมคมและมีอัตราเสี่ยงต่อการบาดเจ็บ</span>
                        </div>
                        <div className={styles.sectionBreak} />
                        <div className={styles.imgSection}>
                            <img src={pic24} className={styles.imgTopSecondary} />
                        </div>
                    </div>
                </Container>
            </div>
        </Page>
    );
};

export default EcoStickerSafety;