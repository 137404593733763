import React from "react";
import { Card, CardContent, Grid, Container } from "@mui/material";
import "./index.css";
import car_phev from "../../assets/detailnextgenerationautomotive/car_phev.jpg";

function SectionPHEVContent({ id }) {
	return (
		<div id={id} className="background-main-phev">
			<div className="main-box-phev">
				<div className="title-section-phev">
					<Grid container>
						<Grid item xs={12}>
							<span className="span-title-section-phev">PHEV : Plug-in Hybrid Electric Vehicle</span>
						</Grid>
					</Grid>

					<Container className="container-custom-phev">
						<Grid container spacing={2} alignItems={"center"}>
							<Grid item xs={7}>
								<Card className="card-section-phev">
									<CardContent>
										<span className="span-content-section-phev">
											ประกอบด้วยเครื่องยนต์เป็นต้นกำลังในการขับเคลื่อนหลัก
											และทำงานร่วมกับมอเตอร์ไฟฟ้าซึ่งมีลักษณะคล้ายคลึงกับรถยนต์ HEV
											แต่จะแตกต่างตรงที่สามารถเสียบชาร์จไฟ จากแหล่งพลังงานภายนอกได้ ทำให้มีชื่อว่า
											Plug-in รวมถึงมีขนาดแบตที่ใหญ่กว่ารถยนต์ HEV ซึ่งจะทำให้รถยนต์ PHEV
											สามารถขับด้วยระบบมอเตอร์ไฟฟ้าได้ระยะทางที่ไกลกว่า ซึ่งบนป้าย ECO Sticker
											ได้มีการแสดงระยะทางที่วิ่งด้วยพลังงานไฟฟ้า
											เพื่อให้ผู้บริโภคสามารถเลือกรถยนต์ให้ได้เหมาะสมกับความต้องการ
										</span>
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs={5}>
								<div className="div-content-image-phev">
									<img src={car_phev} alt="car HEV" />
								</div>
								<div style={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
									<span style={{ color: "#fff", fontSize: "14px" }}>
										ที่มา https://afdc.energy.gov/vehicles/how-do-plug-in-hybrid-electric-cars-work
									</span>
								</div>
							</Grid>
						</Grid>
					</Container>
				</div>
			</div>
		</div>
	);
}

export default SectionPHEVContent;
