import React from 'react'
import ImageBgWaveAbstract from "../../assets/detailnextgenerationautomotive/bg_wave_abstract_ev30.jpeg"
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import CarImageHev from "../../assets/detailnextgenerationautomotive/car_mg_sv_hev_d.png"
import icon_phev_plus from '../../assets/detailnextgenerationautomotive/icon_phev_plus.png'
import plug_in_hybrid_eletric_vehicle from '../../assets/detailnextgenerationautomotive/plug_in_hybrid_eletric_vehicle.png'

const ElectricVehicleInfoSectionPHEV=()=> {
  return (
    //bg-[#F8F9FA]
    <div className="w-full overflow-hidden flex flex-col items-center">
        <div className="w-full max-w-[1600px]">

        <div className="flex flex-col w-full pt-[104px] pb-[56px] gap-[64px] px-[24px]">
            <div className="flex flex-col gap-[20px]">
                <div className="h-[9px] w-[102px] bg-[#559B2D]"></div>
                <p className="text-[36px]">PHEV : <span className="text-[#828282]">Plug-in Hybrid Electric Vehicle</span></p>
                <p className="text-[22px]">ประกอบด้วยเครื่องยนต์เป็นต้นกำลังในการขับเคลื่อนหลัก และทำงานร่วมกับมอเตอร์ไฟฟ้าซึ่งมีลักษณะคล้ายคลึงกับรถยนต์ HEV</p>
            </div>
            <div className="flex flex-col items-center">
                    <img src={icon_phev_plus} className="mb-[20px]"/>
                    <p className="text-[30px] text-center">หลักการทำงานของเครื่องยนต์ PHEV</p>
                    <p className="text-[22px] text-center text-[#828282]">หลักการทำงานของเครื่องยนต์ PHEV จะแตกต่างตรงที่สามารถเสียบชาร์จไฟ<br/> จาก<span className="text-[#339F00]">แหล่งพลังงานภายนอก</span>ได้ ทำให้มีชื่อว่า Plug-in</p>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-1 gap-[24px]">
                <div className="flex flex-col w-full items-center gap-[12px]">
                    <img src={plug_in_hybrid_eletric_vehicle}/>
                    <a href="https://afdc.energy.gov/vehicles/how-do-hybrid-electric-cars-work" target="_blank" className="!underline text-[#0D6EFD] text-[12px] cursor-pointer">ที่มา: U.S. Department of Energy (DOE)</a>
                </div>
               
                <div className="flex flex-col w-full justify-center">
                    <p className="text-[30px]">แบตที่ใหญ่กว่ารถยนต์ HEV</p>
                    <p className="text-[22px] text-[#828282]">ด้วยขนาดแบตที่ใหญ่กว่ารถยนต์ HEV ซึ่งจะทำให้รถยนต์ PHEV สามารถขับด้วยระบบมอเตอร์ไฟฟ้าได้<span className="text-[#339F00]">ระยะทางที่ไกลกว่า</span> ซึ่งบนป้าย ECO Sticker ได้มีการแสดงระยะทางที่วิ่งด้วยพลังงานไฟฟ้า เพื่อให้ผู้บริโภคสามารถเลือกรถยนต์ให้ได้เหมาะสมกับความต้องการ</p>
                </div>
            </div>
        </div>

        {/* section PHEV Car */}
        <div className="bg-gradient-to-b from-[#ffffff00] to-[#ffffff] ">
        <div className="flex flex-col w-full items-center justify-center h-[420px] relative px-[24px]" >
            <p className="text-[38px] text-[#333333] mb-0 leading-[48px] text-center z-10">รถยนต์ <span className="text-[38px] text-[#339F00] mb-0">PHEV</span></p>
            <div className="bg-[rgb(51,51,51)] rounded-full flex justify-center items-center h-[52px] w-[52px] mt-[10px] cursor-pointer z-10">
            <ArrowDownwardRoundedIcon
              className="text-white"
              sx={{ width: 34, height: 34 }}
            />
            </div>
            
            <div className="absolute h-[260px] w-full overflow-hidden flex flex-col top-1/2 -translate-y-1/2 ">
                <img src={CarImageHev} alt='motorcycle' className="h-[310px] w-auto self-end absolute left-[55%] object-contain md:left-[calc(55%+60px)] sm:object-left-top sm:top-[150px] sm:object-cover sm:left-[50%] sm:-translate-y-1/2 "/>
            </div>
            
            <div
            className="w-full h-[420px] absolute bg-no-repeat z-0 opacity-60 !bg-[length:630px_630px] bg-[center] hidden md:block"
            style={
                {
                backgroundImage: `url(${ImageBgWaveAbstract}`,
                backgroundSize: "cover",
                backgroundPosition: "center top -200px",
                WebkitMaskImage: `
                linear-gradient(180deg, transparent 0%, black 0%, transparent 40%),
                linear-gradient(90deg, transparent 0%, black 40%, black 0%, transparent 100%),
                linear-gradient(-90deg, transparent 0%, black 40%, black 0%, transparent 100%)
                `,
                WebkitMaskComposite: "multiply",
                maskImage: `
                    linear-gradient(180deg, transparent 0%, black 0%, transparent 40%),
                    linear-gradient(90deg, transparent 0%, black 40%, black 0%, transparent 100%),
                    linear-gradient(-90deg, transparent 0%, black 40%, black 0%, transparent 100%)
                `,
                maskComposite: "intersect",
                }
            }>
                
            </div>

            <div
            className="w-full h-[420px] absolute bg-no-repeat bg-center z-0 opacity-60 md:hidden"
            style={
                {
                backgroundImage: `url(${ImageBgWaveAbstract}`,
                backgroundSize: "cover",
                WebkitMaskImage: `
                linear-gradient(180deg, transparent 0%, black 0%, transparent 40%),
                linear-gradient(90deg, transparent 0%, black 20%, black 80%, transparent 100%)
                `,
                WebkitMaskComposite: "multiply",
                maskImage: `
                    linear-gradient(180deg, transparent 0%, black 0%, transparent 40%),
                    linear-gradient(90deg, transparent 0%, black 20%, black 80%, transparent 100%)
                `,
                maskComposite: "intersect",
                }
            }>
                
            </div>
        </div>

            {/* CARD COMPONENT THIS AREA*/}
            {/* {CardSecondSlide} */}
        </div> 

        </div>
        
        
    </div>
  )
}

export default ElectricVehicleInfoSectionPHEV

 