import React, { useEffect, useRef, useState } from 'react';
import styles from './CarDetailDynamic.module.scss';
import LazyLoad from 'react-lazy-load';
import classNames from 'classnames';

import EngineIcon1 from '../../assets/carDetail/backdrop/icons/engine-1.png';
import EngineIcon2 from '../../assets/carDetail/backdrop/icons/engine-2.png';
import EngineIcon3 from '../../assets/carDetail/backdrop/icons/engine-3.png';
import EngineIcon4 from '../../assets/carDetail/backdrop/icons/engine-4.png';

import BatteryIcon1 from '../../assets/carDetail/backdrop/icons/battery-1.png';
import BatteryIcon2 from '../../assets/carDetail/backdrop/icons/battery-2.png';
import BatteryIcon3 from '../../assets/carDetail/backdrop/icons/battery-3.png';
import BatteryIcon4 from '../../assets/carDetail/backdrop/icons/battery-4.png';
import BatteryIcon5 from '../../assets/carDetail/backdrop/icons/battery-5.png';
import BatteryIcon6 from '../../assets/carDetail/backdrop/icons/battery-6.png';
import BatteryIcon7 from '../../assets/carDetail/backdrop/icons/battery-7.png';
import useDetailContext from 'src/contexts/detailContext';

import { Button, Stack, Divider } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		primary: {
			light: '#dcedc8',
			main: '#aed581',
			dark: '#558b2f',
			contrastText: '#fff',
		}
	},
});

const CarSizeAndTrye = ({ data, handleGetDetailTyre }) => (
	<ThemeProvider theme={theme}>
		<div style={{ whiteSpace: 'pre-wrap', width: '100%' }}>
			<div className={styles.tryeCarStyle}>{data?.car_style}</div>
			<div className={styles.dimensionsRow}>
				<div className={styles.dimensionsBox}>
					<div>กว้าง</div>
					<div className={styles.dimensionsValue}>{data?.car_width}</div>
				</div>

				<div className={styles.dimensionsValue}>X</div>
				<div className={styles.dimensionsBox}>
					<div>ยาว</div>
					<div className={styles.dimensionsValue}>{data?.car_length}</div>
				</div>

				<div className={styles.dimensionsValue}>X</div>
				<div className={styles.dimensionsBox}>
					<div>สูง</div>
					<div className={styles.dimensionsValue}>{data?.car_height}</div>
				</div>
			</div>

			<div className={styles.dimensionsUnit}>มิลลิเมตร</div>
		</div>
		<div className={styles.blockTyre} >
			<div>
				<div className={styles.tryeOther}>จำนวนที่นั่ง : {data?.car_seats}</div>
				{data?.wheel_size ? (
					<div className={styles.tryeOther}>ขนาดยางล้อ : {data?.wheel_size}</div>
				) : (
					<>
						<div className={styles.tryeOther}>ขนาดยางล้อหน้า : {data?.front_wheel}</div>
						<div className={styles.tryeOther}>ขนาดยางล้อหลัง : {data?.back_wheel}</div>
					</>
				)}
				<div className={styles.tryeOther}>น้ำหนักรถยนต์ : {data?.total_weight} กิโลกรัม</div>
			</div>
			<div>
				<div className={styles.tryeConnectList}>เชื่อมโยง ECO Sticker ยางรถยนต์</div>
				<Stack
					spacing={1}
					mt={1}
				>
					{data?.car_tyre?.map((data, i) => (
						<Stack
							direction={'row'}
							alignItems={'center'}
							justifyContent={'space-between'}
							pl={4}
							pr={4}
						>
							<div>{data?.tyre_id}</div>
							<Button 
							onClick={() => handleGetDetailTyre(data?.tyre_id, `TyreID[${i}]`)}
							size='small' variant="contained" endIcon={<SendIcon />} />
						</Stack>
					))}
				</Stack>
			</div>
		</div>

	</ThemeProvider>
);

const CarDetailDynamic = ({handleGetDetailTyre}) => {
	const [{ data }] = useDetailContext();
	const [showConfig, setShowConfig] = useState({});

	const sectionPage = useRef(null);

	const configShow = () => {
		switch (data?.cartype_name) {
			case 'BEV':
				setShowConfig({
					block_1: false,
					block_2: true,
					block_3: true,
					block_4: false,
				});
				break;
			case 'PHEV':
				setShowConfig({
					block_1: true,
					block_2: true,
					block_3: false,
					block_4: true,
				});
				break;
			default:
				setShowConfig({
					block_1: true,
					block_2: false,
					block_3: true,
					block_4: false,
				});
				break;
		}
	};

	useEffect(() => {
		configShow();
	}, [data]);

	return (
		<>
			<div className={classNames('container-row-detail-3', styles.container)} ref={sectionPage}>
				{showConfig?.block_1 && (
					<LazyLoad className={classNames('row-detail-3', styles.lazyloadContainer)}>
						<div className={styles.block1}>
							<div className={styles.enLeftBlockRow}>
								<div className={styles.enLeftBlockIcon}>
									<img src={EngineIcon1} className={styles.enLeftIcon} />
								</div>
								<div className={styles.enLeftContents}>
									<div className={styles.enLeftBlockTitltle}>เครื่องยนต์</div>
									<div className={styles.enLeftBlockSubTitltle}>{data?.engine_name}</div>
								</div>
							</div>

							<div className={styles.enLeftBlockRow}>
								<div className={styles.enLeftBlockIcon}>
									<img src={EngineIcon2} className={styles.enLeftIcon} />
								</div>
								<div className={styles.enLeftContents}>
									<div className={styles.enLeftBlockTitltle}>ระบบเกียร์</div>
									<div className={styles.enLeftBlockSubTitltle}>{data?.gear_name}</div>
								</div>
							</div>

							<div className={styles.enLeftBlockRow}>
								<div className={styles.enLeftBlockIcon}>
									<img src={EngineIcon3} className={styles.enLeftIcon} />
								</div>
								<div className={styles.enLeftContents}>
									<div className={styles.enLeftBlockTitltle}>เชื้อเพลิง</div>
									<div className={styles.enLeftBlockSubTitltle}>{data?.fuel_name}</div>
								</div>
							</div>

							<div className={styles.enLeftBlockRow}>
								<div className={styles.enLeftBlockIcon}>
									<img src={EngineIcon4} className={styles.enLeftIcon} />
								</div>
								<div className={styles.enLeftContents}>
									<div className={styles.enLeftBlockTitltle}>ความจุกระบอกสูบ</div>
									<div className={styles.enLeftBlockSubTitltle}>{data?.capacity_cylinder_str}</div>
								</div>
							</div>
						</div>
					</LazyLoad>
				)}
				{showConfig?.block_2 && (
					<LazyLoad className={classNames('row-detail-3', styles.lazyloadContainer)}>
						<div className={styles.block2}>
							<div className={styles.batterySpecBlockRow}>
								<div className={styles.batterySpecBlockIcon}>
									<img src={BatteryIcon1} className={styles.enLeftIcon} />
								</div>
								<div className={styles.batterySpecContents}>
									<div className={styles.batterySpecBlockTitltle}>ยี่ห้อแบตเตอรี่</div>
									<div className={styles.batterySpecBlockSubTitltle}>
										{data?.battery_brand || '-'}
									</div>
								</div>
							</div>
							<div className={styles.batterySpecBlockRow}>
								<div className={styles.batterySpecBlockIcon}>
									<img src={BatteryIcon2} className={styles.enLeftIcon} />
								</div>
								<div className={styles.batterySpecContents}>
									<div className={styles.batterySpecBlockTitltle}>มอเตอร์</div>
									<div className={styles.batterySpecBlockSubTitltle}>{data?.motor || '-'}</div>
								</div>
							</div>
							<div className={styles.batterySpecBlockRow}>
								<div className={styles.batterySpecBlockIcon}>
									<img src={BatteryIcon3} className={styles.enLeftIcon} />
								</div>
								<div className={styles.batterySpecContents}>
									<div className={styles.batterySpecBlockTitltle}>ประเภทแบตเตอรี่</div>
									<div className={styles.batterySpecBlockSubTitltle}>{data?.battery_type || '-'}</div>
								</div>
							</div>

							<div className={styles.batterySpecBlockRow}>
								<div className={styles.batterySpecBlockIcon}>
									<img src={BatteryIcon4} className={styles.enLeftIcon} />
								</div>
								<div className={styles.batterySpecContents}>
									<div className={styles.batterySpecBlockTitltle}>แรงดันแบตเตอรี่</div>
									<div className={styles.batterySpecBlockSubTitltle}>
										{data?.nominal_voltage ? `${data?.nominal_voltage} Volt` : '-'}
									</div>
								</div>
							</div>

							<div className={styles.batterySpecBlockRow}>
								<div className={styles.batterySpecBlockIcon}>
									<img src={BatteryIcon5} className={styles.enLeftIcon} />
								</div>
								<div className={styles.batterySpecContents}>
									<div className={styles.batterySpecBlockTitltle}>ความจุแบตเตอรี่</div>
									<div className={styles.batterySpecBlockSubTitltle}>
										{data?.battery_capacity ? `${data?.battery_capacity} Ah` : '-'}
									</div>
								</div>
							</div>

							<div className={styles.batterySpecBlockRow}>
								<div className={styles.batterySpecBlockIcon}>
									<img src={BatteryIcon6} className={styles.enLeftIcon} />
								</div>
								<div className={styles.batterySpecContents}>
									<div className={styles.batterySpecBlockTitltle}>ประเภทชาร์จเจอร์</div>
									<div className={styles.batterySpecBlockSubTitltle}>{data?.type_charge || '-'}</div>
								</div>
							</div>

							<div className={styles.batterySpecBlockRow}>
								<div className={styles.batterySpecBlockIcon}>
									<img src={BatteryIcon7} className={styles.enLeftIcon} />
								</div>
								<div className={styles.batterySpecContents}>
									<div className={styles.batterySpecBlockTitltle}>Charger Voltage</div>
									<div className={styles.batterySpecBlockSubTitltle}>
										{data?.on_board_charger || '-'}
									</div>
								</div>
							</div>
						</div>
					</LazyLoad>
				)}
				{showConfig?.block_3 && (
					<LazyLoad className={classNames('row-detail-3', styles.lazyloadContainer)}>
						<div className={styles.block3}>
							<CarSizeAndTrye data={data} handleGetDetailTyre={handleGetDetailTyre} />
						</div>
					</LazyLoad>
				)}
			</div>
			{showConfig?.block_4 && (
				<div className={classNames(styles.container, styles.containerSocondary)}>
					<LazyLoad width="100%" className={styles.lazyloadContainerCenter}>
						<div className={showConfig?.block_2 ? styles.block4Center : styles.block4}>
							<CarSizeAndTrye data={data} handleGetDetailTyre={handleGetDetailTyre} />
						</div>
					</LazyLoad>
				</div>
			)}
		</>
	);
};

export default CarDetailDynamic;
