import { Backdrop } from '@mui/material';
import classNames from 'classnames';
import { useState } from 'react';
import LazyLoad from 'react-lazy-load';
import styles from './EcoStickerModal.module.scss';
import useDetailContext from 'src/contexts/detailContext';

const EcoStickerModal = ({ open, onClose }) => {
	const [{ isLoaded, sticker, error, isGenerating}] = useDetailContext();

	const [isStickerLoaded, setIsStickerLoaded] = useState(false);
	const [isStickerError, setIsStickerError] = useState(false);

	return (
		<Backdrop sx={{ zIndex: 9999 }} open={open} onClick={() => onClose()}>
			<div className={styles.stickerImageRoot}>
				{!isGenerating && isLoaded && (
					<>
						{error.stickerError || isStickerError ? (
							<div className={styles.stickerNotFoundBlock}>
								<span className={styles.notFoundText}>ไม่พบ ECO Sticker</span>
							</div>
						) : (
							<div className={styles.stickerImageBox}>
								<LazyLoad width="100%" height="90%">
									<img
										src={sticker}
										onLoad={() => {
											setIsStickerLoaded(true);
										}}
										onError={() => {
											setIsStickerError(true);
										}}
										className={styles.ecoImage}
									/>
								</LazyLoad>
								<div className={styles.closeImage} onClick={() => onClose()}>
									ปิด
								</div>
							</div>
						)}
					</>
				)}
				<div
					className={classNames(styles.imageLoadingCover, {
						[styles.loaded]: !error.stickerError ? !isGenerating && isStickerLoaded : error.stickerError,
					})}
				>
					<i className="fa-regular fa-image fa-flip"></i>
					<span className={styles.coverText}>กำลังโหลดข้อมูล ECO Sticker</span>
				</div>
			</div>
		</Backdrop>
	);
};

export default EcoStickerModal;
