import { Stack } from '@mui/material';
import classNames from 'classnames';
import styles from './Section-2.module.scss';

import TisBackdrop from '../../assets/carDetail/backdrop/tis.png';
import EquipBackdrop from '../../assets/carDetail/backdrop/equipments.png';

import EquipmentIcon1 from '../../assets/carDetail/backdrop/icons/equipment-1.png';
import EquipmentIcon2 from '../../assets/carDetail/backdrop/icons/equipment-2.png';
import EquipmentIcon3 from '../../assets/carDetail/backdrop/icons/equipment-3.png';

import CarDetailDynamic from './CarDetailDynamic';
import LazyLoad from 'react-lazy-load';
import EcoStickerModal from './EcoStickerModal';
import { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import useDetailContext from 'src/contexts/detailContext';
import { isNumber } from 'lodash';

export const replaceTaxPercent = (text) => {
	if (!isNumber(parseInt(text))) {
		return text;
	}
	return parseInt(text) + '%';
};

gsap.registerPlugin(ScrollTrigger);

const TopDetailItem = ({ topic, value, tax = false, unit }) => {
	return (
		<div className={styles.detailItem}>
			<div className={styles.detailItemTopic}>{topic}</div>
			<div className={styles.detailItemValue}>
				{tax ? replaceTaxPercent(value) || <NonData /> : value || <NonData />}
			</div>
			<div>{unit ? unit : <>&nbsp;</>}</div>
		</div>
	);
};

const TisStandradItem = ({ topic, active }) => {
	return (
		<div className={classNames('tis-item', styles.tisItem, { [styles.active]: active })}>
			<div className={styles.tisItemTopic}>{topic}</div>
			<div className={styles.tisItemValue}>{active ? 'มีผลการทดสอบ' : 'ไม่มีผลการทดสอบ'}</div>
		</div>
	);
};

const SaftyStandradItem = ({ topic, active }) => {
	return (
		<div className={classNames(styles.saftyItem, { [styles.active]: active })}>
			<div className={styles.saftyItemTopic}>{topic}</div>
			<div className={styles.saftyItemValue}>{active ? 'ผ่านการทดสอบ' : 'ไม่มีผลการทดสอบ'}</div>
		</div>
	);
};

const NonData = () => <span className={styles.nonData}>-</span>;
const Divier = () => <div className={styles.headerDivier} />;

const Section2 = ({ handleGetDetailTyre }) => {
	const [{ data }] = useDetailContext();

	const [openEcoModal, setOpenEcoModal] = useState(false);
	const [sectionCarDetailActive, setSectionCarDetailActive] = useState(false);
	const [sectionCarDetailBackActive, setSectionCarDetailBackActive] = useState(false);

	const section2Page = useRef(null);
	const sectionCarDetail = useRef(null);
	const windowHeight = useRef(window.innerHeight);

	useEffect(() => {
		if (section2Page?.current?.offsetWidth <= 500) {
			let sections1 = gsap.utils.toArray('.row-detail');

			gsap.to(sections1, {
				xPercent: -190 * (sections1.length - 2),
				ease: 'none',
				scrollTrigger: {
					trigger: '.container-row-detail',
					pin: true,
					pinSpacing: true,
					scrub: 1,
					snap: 1 / (sections1.length - 1),
					// start: '+=180',
					start: 'top+=160 top+=240',
					end: () => '+=' + document.querySelector('.container-row-detail').offsetWidth,
					onToggle: ({ isActive }) => setSectionCarDetailActive(isActive),
					onEnterBack: ({ isActive }) => setSectionCarDetailBackActive(isActive),
					onLeaveBack: ({ isActive }) => setSectionCarDetailBackActive(isActive),
				},
			});

			// if (data?.cartype_name !== 'BEV') {
			// 	let sections2 = gsap.utils.toArray('.tis-item');

			// 	gsap.to(sections2, {
			// 		xPercent: -150 * (sections2.length - 2),
			// 		ease: 'none',
			// 		scrollTrigger: {
			// 			trigger: '.container-row-detail-2',
			// 			pin: true,
			// 			pinSpacing: true,
			// 			scrub: 1,
			// 			snap: 1 / (sections2.length - 1),
			// 			start: 'top+=150 top+=250',
			// 			// start: '+=200',
			// 			end: () => '+=' + document.querySelector('.container-row-detail-2').offsetWidth,
			// 			onToggle: ({ isActive }) => setSectionStandardActive(isActive),
			// 		},
			// 	});
			// }

			// let sections2 = gsap.utils.toArray('.tis-item');

			// gsap.to(sections2, {
			// 	xPercent: -150 * (sections2.length - 2),
			// 	ease: 'none',
			// 	scrollTrigger: {
			// 		trigger: '.container-row-detail-2',
			// 		pin: true,
			// 		pinSpacing: true,
			// 		scrub: 1,
			// 		snap: 1 / (sections2.length - 1),
			// 		start: 'top+=180 top+=250',
			// 		// start: '+=200',
			// 		end: () => '+=' + document.querySelector('.container-row-detail-2').offsetWidth,
			// 	},
			// });
		}
	}, []);

	return (
		<div className={styles.containerSection1} ref={section2Page}>
			<Stack
				display="flex"
				direction="row"
				spacing={5}
				mb={3}
				className={classNames('container-row-detail', styles.columnDetailContainerTopDetail)}
				ref={sectionCarDetail}
			>
				<Stack
					display="flex"
					direction="column"
					alignItems="center"
					className={classNames('row-detail', styles.columnDetail)}
					divider={<Divier />}
				>
					<TopDetailItem
						topic={
							<>
								อัตราการปล่อยก๊าซ
								<br />
								(CO<sub>2</sub>)
							</>
						}
						value={data?.emissions_CO2}
						unit="กรัม/กม."
					/>
					{data?.cartype_name !== 'BEV' && (
						<TopDetailItem
							topic={
								<>
									อัตราการใช้พลังงานในเมือง
									<br />
									(Urban)
								</>
							}
							value={
								data?.rate_energy_urban == '' || data?.rate_energy_urban == '0'
									? '-'
									: data?.rate_energy_urban
							}
							unit={data?.consumption_urban_suffix}
						/>
					)}
					{data?.cartype_name !== 'BEV' && (
						<TopDetailItem
							topic={
								<>
									อัตราการใช้พลังงานนอกเมือง
									<br />
									(Extra-Urban)
								</>
							}
							value={
								data?.rate_energy_ex_urban == '' || data?.rate_energy_ex_urban == '0'
									? ''
									: data?.rate_energy_ex_urban
							}
							unit={data?.consumption_urban_suffix}
						/>
					)}
					{data?.cartype_name == 'BEV' && (
						<TopDetailItem
							topic={
								<>
									อัตราการใช้พลังงานรวม
									<br />
									(Combined)
								</>
							}
							value={
								data?.energy_combined_rate == '' || data?.energy_combined_rate == '0'
									? ''
									: data?.energy_combined_rate
							}
							unit={data?.consumption_suffix}
						/>
					)}
				</Stack>
				<Stack
					display="flex"
					direction="column"
					alignItems="center"
					className={classNames('row-detail', styles.columnDetail, styles.invert)}
					divider={<Divier />}
				>
					{data?.cartype_name !== 'BEV' && (
						<TopDetailItem
							topic={
								<>
									อัตราการใช้พลังงานรวม
									<br />
									(Combined)
								</>
							}
							value={
								<>
									{data?.energy_combined_rate}
									{/* {data?.cartype_name === 'PHEV' && ` | ${data?.rate_energy_elec || '-'}`} */}
								</>
							}
							unit={data?.consumption_urban_suffix}
						/>
					)}
					{data?.cartype_name !== 'BEV' && (
						<TopDetailItem
							topic={
								<>
									ปริมาณออกไซต์ของไนโตรเจน
									<br />
									(NOx)
								</>
							}
							value={data?.nox_amount}
							unit="กรัม/กม."
						/>
					)}
					{data?.cartype_name !== 'BEV' && (
						<TopDetailItem
							topic={
								<>
									ปริมาณการปล่อยฝุ่นละออง/มลพิษอนุภาค
									<br />
									(PM)
								</>
							}
							value={data?.particulate}
							unit="กรัม/กม."
						/>
					)}
					{data?.cartype_name == 'BEV' && (
						<TopDetailItem
							topic={
								<>
									ระยะทางสูงสุด
									<br />
									(Driving Range)
								</>
							}
							value={data?.driving_range}
							unit="กม."
						/>
					)}
					{data?.cartype_name == 'BEV' && (
						<TopDetailItem
							topic={
								<>
									อัตราการใช้ไฟฟ้า
									<br />
									(Electric Energy Consumption)
								</>
							}
							value={data?.energy_consumption}
							unit="วัตต์/กม."
						/>
					)}
				</Stack>
				<Stack
					display="flex"
					direction="column"
					alignItems="center"
					className={classNames('row-detail', styles.columnDetail)}
					divider={<Divier />}
				>
					<TopDetailItem topic="ค่าใช้จ่ายด้านพลังงานต่อเดือน" value={data?.price_per_m} unit="บาท" />
					<TopDetailItem topic="อัตราภาษีสรรพสามิต" value={data?.tax_rate_new} unit="" />
				</Stack>
			</Stack>
			<div
				style={{
					position: sectionCarDetailActive ? 'fixed' : 'static',
					top: sectionCarDetailActive ? sectionCarDetail?.current?.clientHeight + 100 : windowHeight.current,
					transitionDuration: '.5s',
					width: '100%',
				}}
			>
				<Stack
					display="flex"
					direction="row"
					justifyContent="center"
					mb={5}
					className={styles.columnDetailContainer}
				>
					<div className={styles.ecoStickerLabel} onClick={() => setOpenEcoModal(true)}>
						ป้าย ECO Sticker
					</div>
				</Stack>

				{data?.cartype_name !== 'BEV' && (
					<Stack
						display="flex"
						direction="column"
						alignItems="center"
						id="container"
						className={classNames('container-row-detail-2', styles.columnDetailContainer)}
						mb={5}
					>
						<div className={styles.environmentStandardTitle}>มาตรฐานสิ่งแวดล้อม</div>
						<Divier />
						<Stack
							display="flex"
							direction="row"
							alignItems="center"
							spacing={5}
							mb={3}
							className={styles.tisContainer}
						>
							<TisStandradItem topic="มอก." active={data?.check_car_standard?.tis != '0'} />
							<TisStandradItem topic="EURO 4" active={data?.check_car_standard?.euro4 != '0'} />
							<TisStandradItem topic="EURO 5" active={data?.check_car_standard?.euro5 != '0'} />
							<TisStandradItem topic="EURO 6" active={data?.check_car_standard?.euro6 != '0'} />
						</Stack>
					</Stack>
				)}
			</div>
			<div
				style={{
					display: sectionCarDetailActive ? 'flex' : 'none',
					height: 290,
				}}
			/>
			{/* 
			<div
				style={{
					display: sectionStandardActive ? 'flex' : 'none',
					height: 290,
				}}
			/> */}

			<LazyLoad height="100%" width="100%">
				<Stack
					display="flex"
					direction="row"
					spacing={5}
					padding={7}
					style={{
						backgroundImage: `url(${TisBackdrop})`,
						backgroundPosition: 'center center',
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
					}}
					mb={5}
					className={styles.saftyContainer}
				>
					<Stack
						direction="column"
						width="100%"
						justifyContent="center"
						className={styles.columnDetailContainerSeftySm}
					>
						<div className={styles.approveStandardTitle}>มาตรฐานความปลอดภัย</div>
						<Stack direction="row" spacing={2} width="100%">
							<Stack width="100%" spacing={2} className={styles.columnDetailSeftySm}>
								<SaftyStandradItem topic="UN R13" active={data?.check_car_standard?.un_reg13 != '0'} />
								<SaftyStandradItem topic="UN R94" active={data?.check_car_standard?.un_reg94 != '0'} />
								<SaftyStandradItem topic="ABS+ESC" active={data?.check_car_standard?.abs_esc != '0'} />
							</Stack>
							<Stack width="100%" spacing={2} className={styles.columnDetailSeftySm}>
								<SaftyStandradItem
									topic="UN R100"
									active={data?.check_car_standard?.un_reg100 != '0'}
								/>
								<SaftyStandradItem
									topic="UN R13H"
									active={data?.check_car_standard?.un_reg13h != '0'}
								/>
								<SaftyStandradItem topic="UN R95" active={data?.check_car_standard?.un_reg95 != '0'} />
							</Stack>
						</Stack>
					</Stack>
					<Stack display="flex" justifyContent="space-evenly" className={styles.columnDetailContainerSefty}>
						<SaftyStandradItem topic="UN R13" active={data?.check_car_standard?.un_reg13 != '0'} />
						<SaftyStandradItem topic="UN R94" active={data?.check_car_standard?.un_reg94 != '0'} />
					</Stack>
					<Stack
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						spacing={5}
						className={styles.columnDetailContainerSefty}
					>
						<SaftyStandradItem topic="ABS+ESC" active={data?.check_car_standard?.abs_esc != '0'} />
						<div className={styles.approveStandardTitle}>มาตรฐานความปลอดภัย</div>
						<SaftyStandradItem topic="UN R100" active={data?.check_car_standard?.un_reg100 != '0'} />
					</Stack>
					<Stack
						display="flex"
						justifyContent="space-evenly"
						alignItems="flex-end"
						className={styles.columnDetailContainerSefty}
					>
						<SaftyStandradItem topic="UN R13H" active={data?.check_car_standard?.un_reg13h != '0'} />
						<SaftyStandradItem topic="UN R95" active={data?.check_car_standard?.un_reg95 != '0'} />
					</Stack>
				</Stack>
			</LazyLoad>

			<Stack
				display="flex"
				direction="column"
				alignItems="center"
				spacing={3}
				marginY={4}
				className={classNames(styles.columnDetailContainer, styles.industryBlock)}
			>
				<div className={styles.industryHeader}>ผู้ผลิต/ผู้นำเข้า</div>

				<div className={styles.industryName}>{data?.factory || 'ไม่พบผู้ชื่อผู้ผลิต/ผู้นำเข้า'}</div>

				<div className={styles.industryStartProduction}>
					<div>ปีที่เริ่มผลิต (Start of Production Year) : {data?.year}</div>
					<div>รุ่นปีที่ผลิต (Model year) : {data?.model_year}</div>
				</div>
			</Stack>

			<CarDetailDynamic carType={data?.cartype_name} handleGetDetailTyre={handleGetDetailTyre} />

			<LazyLoad height="100%" width="100%">
				<Stack
					display="flex"
					direction="column"
					justifyContent="center"
					alignItems="center"
					spacing={3}
					marginY={5}
					className={styles.equipmentDetailContainer}
					style={{
						backgroundImage: `url(${EquipBackdrop})`,
						backgroundPosition: 'center center',
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
					}}
				>
					<div className={styles.equipmentTitle}>อุปกรณ์ที่มีสาระสำคัญ</div>

					<Stack spacing={4} display="flex" direction="row" className={styles.equipmentContainerSm}>
						<div className={styles.equipmentOption}>
							<div className={styles.equipmentOptionIcon}>
								<img src={EquipmentIcon1} />
							</div>

							<div className={styles.equipmentOptionTitle}>ด้านการใช้พลังงาน</div>

							<div className={styles.equipmentOptionList}>
								{data?.car_equip_energy.map((item, idx) => (
									<div key={idx}>
										<div>-</div>
										<div className={styles.equipmentOptionListText}>{item}</div>
									</div>
								))}
							</div>
						</div>

						<div className={styles.equipmentOption}>
							<div className={styles.equipmentOptionIcon}>
								<img src={EquipmentIcon2} />
							</div>

							<div className={styles.equipmentOptionTitle}>ด้านความปลอดภัย</div>

							<div className={styles.equipmentOptionList}>
								{data?.car_equip_safety.length > 0 ? (
									<>
										{data?.car_equip_safety.map((item, idx) => (
											<div key={idx}>
												<div>-</div>
												<div className={styles.equipmentOptionListText}>{item}</div>
											</div>
										))}
									</>
								) : (
									<div className={styles.notfoundData}>ไม่พบข้อมูล</div>
								)}
							</div>
						</div>

						<div className={styles.equipmentOption}>
							<div className={styles.equipmentOptionIcon}>
								<img src={EquipmentIcon3} />
							</div>

							<div className={styles.equipmentOptionTitle}>ระบบเชื่อมโยง</div>

							<div className={styles.equipmentOptionList}>
								{data?.car_equip_connectivity.length > 0 ? (
									<>
										{data?.car_equip_connectivity.map((item, idx) => (
											<div key={idx}>
												<div>-</div>
												<div className={styles.equipmentOptionListText}>{item}</div>
											</div>
										))}
									</>
								) : (
									<div className={styles.notfoundData}>ไม่พบข้อมูล</div>
								)}
							</div>
						</div>
					</Stack>

					<div className={styles.optionTitle}>รายการอุปกรณ์ที่ติดตั้งมาจากโรงงาน</div>

					<div className={styles.optionList}>
						{data?.car_equip_safety.length > 0 ? (
							<>
								{data?.car_equip_factory.map((item, idx) => (
									<div key={idx}>
										<div>-</div>
										<div className={styles.optionListText}>{item}</div>
									</div>
								))}
							</>
						) : (
							<div className={styles.notfoundData}>ไม่พบข้อมูล</div>
						)}
					</div>

					<div className={styles.downloadBrochur}>
						<a href={data?.brochure_link} className={styles.downloadBrochurButton} target="_blank">
							<div>ดาวน์โหลดโบรชัวร์</div>
						</a>
					</div>
				</Stack>
			</LazyLoad>

			<EcoStickerModal open={openEcoModal} onClose={() => setOpenEcoModal(false)} />
		</div>
	);
};

export default Section2;
