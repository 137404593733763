import { Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Page } from '../components/GobalElement';
import HeaderPage from '../components/HeaderPage';
import HeaderTopic from '../components/HeaderTopic';
import SearchInput from '../components/SearchInput';
import { SelectType } from './StickerPage';
import mainStyles from './StickerPage.module.scss';
import Bg from '../assets/header_bg_2.png';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { searchSticker } from '../helper/allAPI';
import CarListLoading from '../components/loading/CarListLoading';
import NotFound from '../components/NotFound';
import styles from './EcoStickerPage.module.scss';
import { styled } from '@mui/system';
import classNames from 'classnames';
import useSticker from '../helper/stickers';

const Car = styled('div')(({ img }) => ({
	backgroundImage: `url(${img})`,
	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	width: '100%',
	maxWidth: 850,
	height: 400,
	borderRadius: 10,
	overflow: 'hidden',
	margin: '2rem 0',
	'@media screen and (max-width: 900px)': {
		maxWidth: '100%',
		backgroundSize: 'contain',
	},
	'@media screen and (max-width: 700px)': {
		height: 350,
	},
	'@media screen and (max-width: 550px)': {
		height: 250,
	},
	'@media screen and (max-width: 450px)': {
		height: 200,
	},
}));

const EcoStickerPage = () => {
	const { getSticker, isGenerating } = useSticker();

	const [type, setType] = useState({
		text: 'หมายเลขตัวถังประจำรถ (VIN)',
		val: 'vinno',
	});
	const [data, setData] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [isLoadImage, setIsLoadImage] = useState(true);
    const [sticker, setSticker] = useState('');

	const navigate = useNavigate();

	const [searchParams] = useSearchParams();
	const search = useLocation().search;

	const select_field = searchParams.get('type');
	const id = searchParams.get('id');

	const handleSearch = (text) => {
		navigate(`/sticker-search?type=${type.val}&id=${text}`);
	};

	const fetchDataSticker = async () => {
		setIsLoading(true);
		setIsLoadImage(true);
		setData({});
		const select_field = searchParams.get('type');
		const id = searchParams.get('id');
		const queryString = new URLSearchParams({
			select_field: select_field || '',
			search: id || '',
		}).toString();
		try {
			const res = await searchSticker(queryString);
			setData(res.data.data);
			const responseSticker = await getSticker(res.data.data.id,true);
            setSticker(responseSticker)
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (!select_field || !id) {
			return navigate(`/sticker`);
		}
		setType(
			select_field == 'eco_sticker_id'
				? {
						text: 'ไอดี ( ID )',
						val: 'eco_sticker_id',
				  }
				: {
						text: 'หมายเลขตัวถังประจำรถ (VIN)',
						val: 'vinno',
				  }
		);
		fetchDataSticker();
	}, []);

	useEffect(() => {
		fetchDataSticker();
	}, [search]);

	const handleViewDetail = (id) => {
		navigate(`/detail/${id}`);
	};

	const handleChangeSelect = (item) => {
		setType(item);
	};

	return (
		<Page title={`ค้นหาสติ๊กเกอร์${id ? `${select_field == 'id' ? 'ไอดี' : 'หมายเลขตัวถังประจำรถ'}: ${id}` : ''}`}>
			<HeaderPage classmName={mainStyles.headOverride} bg={Bg}>
				<div className={mainStyles.headContainer}>
					<SearchInput
						onSearch={handleSearch}
						placeholder={type.text}
						textSearch={id || ''}
						onBlurSubmit={false}
						frontComp={
							<SelectType
								selected={type}
								onChange={handleChangeSelect}
								option={[
									{
										text: 'หมายเลขตัวถังประจำรถ (VIN)',
										val: 'vinno',
									},
									{
										text: 'ไอดี ( ID )',
										val: 'eco_sticker_id',
									},
								]}
							/>
						}
						mask={
							type.val === 'eco_sticker_id'
								? {
										len: 6,
										maskText: '000000',
								  }
								: ''
						}
					/>
				</div>
			</HeaderPage>
			<HeaderTopic topic="ข้อมูล ECO Sticker" />
			<div className={mainStyles.pageContent}>
				<Container>
					{isLoading || isGenerating ? (
						<CarListLoading key={0} />
					) : (
						<>
							{Object.keys(data ?? {})?.length > 0 ? (
								<div className={mainStyles.pageContainer}>
									<span className={styles.idText}>ID : {data.running_code}</span>
									<div className={styles.headerText}>{data.brand}</div>
									<div className={styles.headerTextSecondary}>{data.model.toUpperCase()}</div>
									<div className={mainStyles.headerLine}></div>
									<Car img={data.logo_url} />
									<div className={styles.viewDetail} onClick={() => handleViewDetail(data.id)}>
										รายละเอียดเพิ่มเติม
									</div>
									<div
										className={classNames(styles.ecoStickerImageBox, {
											[styles.ecoStickerImageLoaded]: !isLoadImage,
										})}
									>
										<div
											className={classNames(styles.ecoStickerOverImage, {
												[styles.ecoStickerImageLoaded]: !isLoadImage,
											})}
										>
											กำลังโหลดรูปภาพ...
										</div>
										<img
											src={sticker}
											onLoad={() => setIsLoadImage(false)}
											className={styles.ecoStickerImage}
										/>
									</div>
								</div>
							) : (
								<NotFound detail="ไม่พบข้อมูล" goBack={false} />
							)}
						</>
					)}
				</Container>
			</div>
		</Page>
	);
};

export default EcoStickerPage;
