import styles from "./CarDetail.module.scss";
import React, { useEffect, useState, Fragment } from "react";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import { Grid, Stack } from "@mui/material";
import { getCarDetail } from "../helper/allAPI";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";

import CircularProgress from "@mui/material/CircularProgress";
import mockMainEVImage from "../assets/carDetail/mock_main_ev_car_img.png";
import mockMainICEImage from "../assets/carDetail/mock_main_ice_car_img.png";
import mockMainHEVImage from "../assets/carDetail/mock_main_hev_car_img.png";
import mockAltImage from "../assets/carDetail/mock_alt_car_img.png";
import useSticker from "../helper/stickers";
import NotFound from "../components/NotFound";
import styled from "@emotion/styled";

const EnhancedBox = styled(Box)(() => ({
	width: 300,
	height: 150,
	overflow: "hidden",
	borderRadius: 5,
}));

const Loader = () => (
	<Box
		sx={{
			height: "100vh",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			color: "#2B9200",
		}}
	>
		<CircularProgress color="inherit" size="8rem" />
	</Box>
);

const CarDetail = () => {
	const { getSticker, isGenerating } = useSticker();
	const [sticker, setSticker] = useState("");
	let { id } = useParams();
	const [isLoaded, setIsLoaded] = useState(false);
	const [stickerImage, setStickerImage] = useState({
		isModalOpen: false,
		isStickerLoaded: false,
	});
	const [error, setError] = useState({
		fetchError: false,
		stickerError: false,
	});
	const [data, setData] = useState(null);

	const getEnergyConsumptionSuffix = (type) => {
		let res = { consumption_suffix: "", consumption_urban_suffix: "" };
		switch (type) {
			case "ICE":
				res.consumption_suffix = "(ลิตร/100 กม.)";
				res.consumption_urban_suffix = "(ลิตร/100 กม.)";
				break;
			case "BEV":
				res.consumption_suffix = "(วัตต์/100 กม.)";
				res.consumption_urban_suffix = "(วัตต์/100 กม.";
				break;
			case "PHEV":
				res.consumption_suffix = "(วัตต์/100 กม.) | (ลิตร/100 กม.)";
				res.consumption_urban_suffix = "(ลิตร/100 กม.)";
				break;
			default:
				return;
		}
		return res;
	};

	const fetchData = async () => {
		try {
			const {
				data: { data },
			} = await getCarDetail(id);
			const responseSticker = await getSticker(id, true);
			const consumptionSuffix = getEnergyConsumptionSuffix(data.cartype_name);
			setSticker(responseSticker);
			setData({ ...data, ...consumptionSuffix });
			setIsLoaded(true);
		} catch (e) {
			setIsLoaded(true);
			// navigate('/404');
		}
	};

	useEffect(() => {
		if (id) {
			fetchData();
		} else {
			setIsLoaded(true);
		}
	}, []);

	const getEngineAndDimensionsSpec = (type) => {
		const engineSpec = (
			<div className={styles.enLeftBlock}>
				<div className={styles.enLeftBlockRow}>
					<div className={styles.enLeftBlockIcon}>
						<i className="fa-solid fa-car-battery fa-2x"></i>
					</div>
					<div className={styles.enLeftContents}>
						<div className={styles.enLeftBlockTitltle}>เครื่องยนต์</div>
						<div className={styles.enLeftBlockSubTitltle}>{data.engine_name}</div>
					</div>
				</div>

				<div className={styles.enLeftBlockRow}>
					<div className={styles.enLeftBlockIcon}>
						<i className="fa-solid fa-gear fa-2x"></i>
					</div>
					<div className={styles.enLeftContents}>
						<div className={styles.enLeftBlockTitltle}>ระบบเกียร์</div>
						<div className={styles.enLeftBlockSubTitltle}>{data.gear_name}</div>
					</div>
				</div>

				<div className={styles.enLeftBlockRow}>
					<div className={styles.enLeftBlockIcon}>
						<i className="fa-solid fa-gas-pump fa-2x"></i>
					</div>
					<div className={styles.enLeftContents}>
						<div className={styles.enLeftBlockTitltle}>เชื้อเพลิง</div>
						<div className={styles.enLeftBlockSubTitltle}>{data.fuel_name}</div>
					</div>
				</div>

				<div className={styles.enLeftBlockRow}>
					<div className={styles.enLeftBlockIcon}>
						<i className="fa-solid fa-car fa-2x"></i>
					</div>
					<div className={styles.enLeftContents}>
						<div className={styles.enLeftBlockTitltle}>ความจุกระบอกสูบ (ซีซี)</div>
						<div className={styles.enLeftBlockSubTitltle}>{data.capacity_cylinder_str}</div>
					</div>
				</div>
			</div>
		);
		const batterySpec = (
			<div className={styles.batterySpecBlock}>
				<div className={styles.batterySpecBlockRow}>
					<div className={styles.batterySpecBlockIcon}>
						<span>
							<i className="fa-solid fa-battery-empty fa-2x"></i>
							<div
								style={{
									fontSize: "0.5em",
									textAlign: "center",
									lineHeight: "0.5",
								}}
							>
								BRAND
							</div>
						</span>
					</div>
					<div className={styles.batterySpecContents}>
						<div className={styles.batterySpecBlockTitltle}>ยี่ห้อแบตเตอรี่</div>
						<div className={styles.batterySpecBlockSubTitltle}>{data.battery_brand || "-"}</div>
					</div>
				</div>
				<div className={styles.batterySpecBlockRow}>
					<div className={styles.batterySpecBlockIcon}>
						<i className="fa-solid fa-gears fa-2x"></i>
					</div>
					<div className={styles.batterySpecContents}>
						<div className={styles.batterySpecBlockTitltle}>มอเตอร์</div>
						<div className={styles.batterySpecBlockSubTitltle}>{data.motor || "-"}</div>
					</div>
				</div>
				<div className={styles.batterySpecBlockRow}>
					<div className={styles.batterySpecBlockIcon}>
						<i className="fa-solid fa-car-battery fa-2x"></i>
					</div>
					<div className={styles.batterySpecContents}>
						<div className={styles.batterySpecBlockTitltle}>ประเภทแบตเตอรี่</div>
						<div className={styles.batterySpecBlockSubTitltle}>{data.battery_type || "-"}</div>
					</div>
				</div>

				<div className={styles.batterySpecBlockRow}>
					<div className={styles.batterySpecBlockIcon}>
						<i className="fa-solid fa-battery-empty fa-2x"></i>
					</div>
					<div className={styles.batterySpecContents}>
						<div className={styles.batterySpecBlockTitltle}>แรงดันแบตเตอรี่</div>
						<div className={styles.batterySpecBlockSubTitltle}>{data.nominal_voltage || "-"}</div>
					</div>
				</div>

				<div className={styles.batterySpecBlockRow}>
					<div className={styles.batterySpecBlockIcon}>
						<i className="fa-solid fa-battery-three-quarters fa-2x"></i>
					</div>
					<div className={styles.batterySpecContents}>
						<div className={styles.batterySpecBlockTitltle}>ความจุแบตเตอรี่</div>
						<div className={styles.batterySpecBlockSubTitltle}>{data.battery_capacity || "-"}</div>
					</div>
				</div>

				<div className={styles.batterySpecBlockRow}>
					<div className={styles.batterySpecBlockIcon}>
						<i className="fa-solid fa-charging-station fa-2x"></i>
					</div>
					<div className={styles.batterySpecContents}>
						<div className={styles.batterySpecBlockTitltle}>ประเภทชาร์จเจอร์</div>
						<div className={styles.batterySpecBlockSubTitltle}>{data.type_charge || "-"}</div>
					</div>
				</div>

				<div className={styles.batterySpecBlockRow}>
					<div className={styles.batterySpecBlockIcon}>
						<i className="fa-solid fa-bolt fa-2x"></i>
					</div>
					<div className={styles.batterySpecContents}>
						<div className={styles.batterySpecBlockTitltle}>Charger Voltage</div>
						<div className={styles.batterySpecBlockSubTitltle}>{data.on_board_charger || "-"}</div>
					</div>
				</div>
			</div>
		);

		const dimensionsSpec = (
			<div className={styles.enRightBlock}>
				<div className={styles.enRightCarType}>{data.car_style}</div>

				<div className={styles.justifyContentCenter}>
					<div className={styles.enRightDimensionsFlex}>
						<div className={styles.enRightDimensionsRowTop}>
							<div>กว้าง</div>
							<div>ยาว</div>
							<div>สูง</div>
						</div>

						<div className={styles.enRightDimensionsRowMiddle}>
							<div>{data.car_width}</div>
							<div className={styles.enRightDimensionsMultiply}>X</div>
							<div>{data.car_length}</div>
							<div className={styles.enRightDimensionsMultiply}>X</div>
							<div>{data.car_height}</div>
						</div>

						<div className={styles.enRightDimensionsRowBottom}>มิลลิเมตร</div>
					</div>
				</div>

				<div className={styles.enRightBlockSpecs}>
					<div>จำนวนที่นั่ง : {data.car_seats}</div>
					{data.wheel_size ? (
						<div>ขนาดยางล้อ : {data.wheel_size}</div>
					) : (
						<Fragment>
							<div>
								<div>ขนาดยางล้อหน้า : {data.front_wheel}</div>
							</div>
							<div>
								<div>ขนาดยางล้อหลัง : {data.back_wheel}</div>
							</div>
						</Fragment>
					)}
					<div>น้ำหนักรถยนต์ (กิโลกรัม) : {data.total_weight}</div>
				</div>
			</div>
		);

		const dimenSionSpecFullWidth = (
			<div className={styles.justifyContentCenter}>
				<div className={styles.fullWidthBlock}>
					<div className={styles.fullWidthCarType}>{data.car_style}</div>

					<div className={styles.justifyContentCenter}>
						<div className={styles.fullWidthDimensionsFlex}>
							<div className={styles.fullWidthDimensionsRowTop}>
								<div>กว้าง</div>
								<div>ยาว</div>
								<div>สูง</div>
							</div>

							<div className={styles.fullWidthDimensionsRowMiddle}>
								<div>{data.car_width}</div>
								<div className={styles.fullWidthDimensionsMultiply}>X</div>
								<div>{data.car_length}</div>
								<div className={styles.fullWidthDimensionsMultiply}>X</div>
								<div>{data.car_height}</div>
							</div>

							<div className={styles.fullWidthDimensionsRowBottom}>มิลลิเมตร</div>
						</div>
					</div>

					<div className={styles.fullWidthBlockSpecs}>
						<div>จำนวนที่นั่ง : {data.car_seats}</div>
						{data.wheel_size ? (
							<div>ขนาดยางล้อ : {data.wheel_size}</div>
						) : (
							<Fragment>
								<div>
									<div>ขนาดยางล้อหน้า : {data.front_wheel}</div>
								</div>
								<div>
									<div>ขนาดยางล้อหลัง : {data.back_wheel}</div>
								</div>
							</Fragment>
						)}
						<div>น้ำหนักรถยนต์ (กิโลกรัม) : {data.total_weight}</div>
					</div>
				</div>
			</div>
		);
		const res = (
			<Fragment>
				<div className={styles.engineBlock}>
					{type === "BEV" ? batterySpec : engineSpec}
					{type === "PHEV" ? batterySpec : dimensionsSpec}
				</div>
				{type === "PHEV" && dimenSionSpecFullWidth}
			</Fragment>
		);
		return res;
	};

	const ImageBackDrop = () => {
		return (
			<Backdrop
				sx={{ color: "#fff", zIndex: 9999 }}
				open={stickerImage.isModalOpen}
				onClick={() => setStickerImage({ ...stickerImage, isModalOpen: false })}
			>
				<div
					className={styles.stickerImageRoot}
					style={{ display: stickerImage.isStickerLoaded ? "flex" : "none" }}
				>
					{error.stickerError ? (
						<div className={styles.stickerNotFoundBlock}>
							<div className={styles.closeButton}>
								<i className="fa-solid fa-x fa-lg"></i>
							</div>
							<div>
								<span className={styles.notFoundText}>ไม่พบข้อมูล Sticker</span>
							</div>
						</div>
					) : (
						<div style={{ display: "flex" }}>
							<img
								src={sticker}
								style={{ width: `100%` }}
								onLoad={() => {
									if (!stickerImage.isStickerLoaded)
										setStickerImage({ ...stickerImage, isStickerLoaded: true });
								}}
								onError={() => {
									if (!error.stickerError) {
										setError({ ...error, stickerError: true });
										setStickerImage({ ...stickerImage, isStickerLoaded: true });
									}
								}}
								alt="main_image"
								className={styles.mainImage}
							/>

							<div className={styles.closeButtonWrapper}>
								<i className="fa-solid fa-x fa-2x"></i>
							</div>
						</div>
					)}
				</div>
				{!stickerImage.isStickerLoaded && <CircularProgress color="inherit" />}
			</Backdrop>
		);
	};

	return (
		<div className={styles.cardetailContainer}>
			{/* data && isLoaded */}
			{!isLoaded && <Loader />}
			{isLoaded && data && (
				<div>
					<ImageBackDrop />
					<div className={styles.approveDate}>
						อนุมัติเมื่อ {moment(data.approve_date).format("DD/MM/yyyy")}
					</div>
					{/* <Stack spacing={2}> */}

					<div className={styles.brand}>{data.brand}</div>

					<div className={styles.model}>{data.model}</div>
					<div className={styles.justifyContentCenter}>
						<div className={styles.headerDivier} />
					</div>
					{/* </Stack>
					 */}
					{/* <div className={styles.generation}>รุ่น e:HEV TECH</div> */}

					<Grid container justifyContent="center" p={3}>
						<Grid item xs={10} md={8}>
							<div className={styles.justifyContentCenter}>
								<img
									src={data.logo_link}
									onError={({ currentTarget }) => {
										let src = mockMainICEImage;
										if (data.cartype_name === "BEV") {
											src = mockMainEVImage;
										} else if (data.cartype_name === "PHEV") {
											src = mockMainHEVImage;
										}
										currentTarget.src = src;
									}}
									alt="main_image"
									className={styles.mainImage}
								/>
							</div>
						</Grid>
					</Grid>

					{data.pic_car_external.length > 0 && (
						<div className={styles.justifyContentCenter}>
							<Stack overflow="scroll" direction="row" justifyContent="center" spacing={1}>
								{data.pic_car_external.map((src, idx) => (
									<>
										<EnhancedBox>
											<img
												key={idx}
												src={src}
												onError={({ currentTarget }) => {
													currentTarget.src = mockAltImage;
												}}
												alt="subImage1"
												className={styles.subImage}
											/>
										</EnhancedBox>
										{/* <EnhancedBox>
										<img
											key={idx}
											src={src}
											onError={({ currentTarget }) => {
												currentTarget.src = mockAltImage;
											}}
											alt="subImage1"
											className={styles.subImage}
										/>
									</EnhancedBox> */}
									</>
								))}
							</Stack>
						</div>
						// <div className={styles.justifyContentCenter}>
						// 	<div id="subImageScroll" className={styles.subImageScrollbar}>
						// 		{data.pic_car_external.map((src, idx) => (
						// 			<div>
						// 				<img
						// 					key={idx}
						// 					src={src}
						// 					onError={({ currentTarget }) => {
						// 						currentTarget.src = mockAltImage;
						// 					}}
						// 					alt="subImage1"
						// 					className={styles.subImage}
						// 				/>
						// 			</div>
						// 		))}
						// 	</div>
						// </div>
					)}
					<div className={styles.justifyContentCenter}>
						<div
							className={styles.ecoStickerLabel}
							onClick={() => setStickerImage({ ...stickerImage, isModalOpen: true })}
						>
							ป้าย ECO Sticker
						</div>
					</div>
					<div className={styles.recommendedRetailPriceText}>ราคาขายปลีกที่แนะนำ ( บาท )</div>
					<div className={styles.recommendedRetailPriceNumber}>{data.recomend_retail_price_new}</div>
					{data.remark_price.replace(/\s/g, "") && (
						<div className={styles.remarkBlock}>
							<div className={styles.remarkTitle}>**หมายเหตุ&nbsp;&nbsp;:</div>
							<div className={styles.remarkDetail}>{data.remark_price}</div>
						</div>
					)}
					<Grid container direction="row" justifyContent="center" alignItems="center">
						<Grid item xs={12} sm={12} md={5}>
							<div className={styles.contentTitle}>ค่าใช้จ่ายด้านพลังงานต่อเดือน (บาท)</div>
							<div className={styles.contentDetail}>{data.price_per_m}</div>
						</Grid>
						<Grid item xs={12} sm={12} md={5}>
							<div className={styles.contentTitle}>คาดการณ์อัตราสรรพสามิต</div>
							<div className={styles.contentDetail}>{data.tax_rate_new}</div>
						</Grid>
					</Grid>
					<Grid container direction="row" justifyContent="center" alignItems="center">
						<Grid item xs={12} sm={12} md={5}>
							<div className={styles.contentTitle}>
								ปริมาณการปล่อยฝุ่นละออง/
								<br />
								มลพิษอนุภาค (PM) (กรัม/กม.)
							</div>
							<div className={styles.contentDetail}>{data.particulate}</div>
						</Grid>
						<Grid item xs={12} sm={12} md={5}>
							<div className={styles.contentTitle}>
								ปริมาณออกไซต์ของไนโตรเจน
								<br />
								(NOx) (กรัม/กม.)
							</div>
							<div className={styles.contentDetail}>{data.nox_amount}</div>
						</Grid>
					</Grid>
					<Grid container direction="row" justifyContent="center" alignItems="center">
						<Grid item xs={12} sm={12} md={5}>
							<div className={styles.contentTitle}>
								อัตราการปล่อยก๊าซ <br />
								CO2 (กรัม/กม.)
							</div>
							<div className={styles.contentDetail}>{data.emissions_CO2}</div>
						</Grid>
						<Grid item xs={12} sm={12} md={5}>
							<div className={styles.contentTitle}>
								อัตราการใช้พลังงานรวม (Combined)
								<br />
								{data.rate_cal}
							</div>
							<div className={styles.contentDetail}>
								{data.rate_energy}
								{data.cartype_name === "PHEV" && ` | ${data.rate_energy_elec || "-"}`}
							</div>
						</Grid>
					</Grid>
					{data.cartype_name !== "BEV" && (
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Grid item xs={12} sm={12} md={5}>
								<div className={styles.contentTitle}>
									อัตราการใช้พลังงานนอกเมือง (Extra-Urban)
									<br />
									{data.consumption_urban_suffix}
								</div>
								<div className={styles.contentDetail}>
									{data.rate_energy_ex_urban == "" || data.rate_energy_ex_urban == "0"
										? "-"
										: data.rate_energy_ex_urban}
								</div>
							</Grid>
							<Grid item xs={12} sm={12} md={5}>
								<div className={styles.contentTitle}>
									อัตราการใช้พลังงานในเมือง (Urban)
									<br />
									{data.consumption_urban_suffix}
								</div>
								<div className={styles.contentDetail}>
									{data.rate_energy_urban == "" || data.rate_energy_urban == "0"
										? "-"
										: data.rate_energy_urban}
								</div>
							</Grid>
						</Grid>
					)}
					{data.cartype_name !== "BEV" && (
						<Fragment>
							<div className={styles.environmentStandardTitle}>มาตรฐานสิ่งแวดล้อม</div>

							<Grid container justifyContent="center" spacing={2}>
								<Grid container justifyContent="center" item xs={12} sm={12} md={5}>
									<div
										className={
											data.check_car_standard?.tis == "0" || data.check_car_standard?.tis == ""
												? styles.environmentStandardInActive
												: styles.environmentStandardActive
										}
									>
										<div>มอก.</div>
										{data.check_car_standard?.tis == "0" ||
											(data.check_car_standard?.tis == "" && <div>ไม่มีผลการทดสอบ</div>)}
										{/* <div>มอก. 2540-2554</div> */}
									</div>
								</Grid>

								<Grid container justifyContent="center" item xs={12} sm={12} md={5}>
									<div
										className={
											data.check_car_standard?.euro4 == "0" ||
											data.check_car_standard?.euro4 == ""
												? styles.environmentStandardInActive
												: styles.environmentStandardActive
										}
									>
										<div>EURO 4</div>

										{data.check_car_standard?.euro4 == "0" ||
											(data.check_car_standard?.euro4 == "" && <div>ไม่มีผลการทดสอบ</div>)}
									</div>
								</Grid>
							</Grid>

							<Grid container justifyContent="center" spacing={2}>
								<Grid container justifyContent="center" item xs={12} sm={12} md={5}>
									<div
										className={
											data.check_car_standard?.euro5 == "0" ||
											data.check_car_standard?.euro5 == ""
												? styles.environmentStandardInActive
												: styles.environmentStandardActive
										}
									>
										<div>EURO 5</div>

										{data.check_car_standard?.euro5 == "0" ||
											(data.check_car_standard?.euro5 == "" && <div>ไม่มีผลการทดสอบ</div>)}
									</div>
								</Grid>

								<Grid container justifyContent="center" item xs={12} sm={12} md={5}>
									<div
										className={
											data.check_car_standard?.euro6 == "0" ||
											data.check_car_standard?.euro6 == ""
												? styles.environmentStandardInActive
												: styles.environmentStandardActive
										}
									>
										<div>EURO 6</div>

										{data.check_car_standard?.euro6 == "0" ||
											(data.check_car_standard?.euro6 == "" && <div>ไม่มีผลการทดสอบ</div>)}
									</div>
								</Grid>
							</Grid>
						</Fragment>
					)}
					<div className={styles.approveStandardTitle}>มาตรฐานความปลอดภัย</div>

					<div className={styles.approveStandardContainer}>
						<div
							className={
								data.check_car_standard?.abs_esc == "0" || data.check_car_standard?.abs_esc == ""
									? styles.approveStandardBoxInActive
									: styles.approveStandardBoxActive
							}
						>
							<div>ABS+ESC</div>
							{data.check_car_standard?.abs_esc == "0" ||
								(data.check_car_standard?.abs_esc == "" && <div>ไม่มีผลการทดสอบ</div>)}
						</div>

						<div
							className={
								data.check_car_standard?.un_reg13h == "0" || data.check_car_standard?.un_reg13h == ""
									? styles.approveStandardBoxInActive
									: styles.approveStandardBoxActive
							}
						>
							<div>UN R13H</div>
							{data.check_car_standard?.un_reg13h == "0" ||
								(data.check_car_standard?.un_reg13h == "" && <div>ไม่มีผลการทดสอบ</div>)}
						</div>

						<div
							className={
								data.check_car_standard?.un_reg13 == "0" || data.check_car_standard?.un_reg13 == ""
									? styles.approveStandardBoxInActive
									: styles.approveStandardBoxActive
							}
						>
							<div>UN R13</div>
							{data.check_car_standard?.un_reg13 == "0" ||
								(data.check_car_standard?.un_reg13 == "" && <div>ไม่มีผลการทดสอบ</div>)}
						</div>
					</div>

					<div className={styles.approveStandardContainer}>
						<div
							className={
								data.check_car_standard?.un_reg94 == "0" || data.check_car_standard?.un_reg94 == ""
									? styles.approveStandardBoxInActive
									: styles.approveStandardBoxActive
							}
						>
							<div>UN R94</div>
							{data.check_car_standard?.un_reg94 == "0" ||
								(data.check_car_standard?.un_reg94 == "" && <div>ไม่มีผลการทดสอบ</div>)}
						</div>

						<div
							className={
								data.check_car_standard?.un_reg95 == "0" || data.check_car_standard?.un_reg95 == ""
									? styles.approveStandardBoxInActive
									: styles.approveStandardBoxActive
							}
						>
							<div>UN R95</div>
							{data.check_car_standard?.un_reg95 == "0" ||
								(data.check_car_standard?.un_reg95 == "" && <div>ไม่มีผลการทดสอบ</div>)}
						</div>

						<div
							className={
								data.check_car_standard?.un_reg100 == "0" || data.check_car_standard?.un_reg100 == ""
									? styles.approveStandardBoxInActive
									: styles.approveStandardBoxActive
							}
						>
							<div>UN R100</div>
							{data.check_car_standard?.un_reg100 == "0" ||
								(data.check_car_standard?.un_reg100 == "" && <div>ไม่มีผลการทดสอบ</div>)}
						</div>
					</div>

					<div className={styles.justifyContentCenter}>
						<div className={styles.industryBlock}>
							<div className={styles.industryContentFlex}>
								<div className={styles.industryHeader}>ผู้ผลิต/ผู้นำเข้า</div>

								<div className={styles.industryName}>{data.factory}</div>

								<div className={styles.industryStartProduction}>
									<div>ปีที่เริ่มผลิต (Start of Production Year) : {data.year}</div>
									<div>รุ่นปีที่ผลิต (Model year) : {data.model_year}</div>
								</div>
							</div>
						</div>
					</div>

					{getEngineAndDimensionsSpec(data.cartype_name)}

					<div className={styles.justifyContentCenter}>
						<div className={styles.brochureBlock}>
							<div className={styles.flexColumn}>
								<div className={styles.brochureTitle}>อุปกรณ์ที่มีสาระสำคัญ</div>

								<div className={styles.brochureContainer}>
									<div className={styles.brochureContents}>
										<div className={styles.brochureOption}>
											<div className={styles.brochureOptionIcon}>
												<i className="fa-solid fa-leaf fa-4x"></i>
											</div>

											<div className={styles.brochureOptionTitle}>ด้านการใช้พลังงาน</div>

											<div className={styles.brochureOptionList}>
												{data.car_equip_energy.map((item, idx) => (
													<li key={idx}>- {item}</li>
												))}
											</div>
										</div>

										<div className={styles.brochureOption}>
											<div className={styles.brochureOptionIcon}>
												<i className="fa-solid fa-car-rear fa-4x"></i>
											</div>

											<div className={styles.brochureOptionTitle}>ด้านความปลอดภัย</div>

											<div className={styles.brochureOptionList}>
												{data.car_equip_safety.map((item, idx) => (
													<li key={idx}>- {item}</li>
												))}
											</div>
										</div>

										<div className={styles.brochureOption}>
											<div className={styles.brochureOptionIcon}>
												<i className="fa-solid fa-wifi fa-4x"></i>
											</div>

											<div className={styles.brochureOptionTitle}>ระบบเชื่อมโยง</div>

											<div className={styles.brochureOptionList}>
												{data.car_equip_connectivity.map((item, idx) => (
													<li key={idx}>- {item}</li>
												))}
											</div>
										</div>
									</div>

									<div className={styles.justifyContentCenter}>
										<div>
											<div className={styles.optionTitle}>รายการอุปกรณ์ที่ติดตั้งมาจากโรงงาน</div>

											<div className={styles.optionList}>
												{data.car_equip_factory.map((item, idx) => (
													<div key={idx}>- {item}</div>
												))}
											</div>
										</div>
									</div>

									<div className={styles.downloadBrochureButton}>
										<a href={data.brochure_link}>
											<div>ดาวน์โหลดโบรชัวร์</div>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			{isLoaded && !data && <NotFound detail="ไม่พบข้อมูล" goBack={false} />}
		</div>
	);
};

export default CarDetail;
